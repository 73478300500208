// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---gatsby-theme-newscloud-core-src-pages-404-js": () => import("./../../../../gatsby-theme-newscloud-core/src/pages/404.js" /* webpackChunkName: "component---gatsby-theme-newscloud-core-src-pages-404-js" */),
  "component---gatsby-theme-newscloud-core-src-pages-account-js": () => import("./../../../../gatsby-theme-newscloud-core/src/pages/account.js" /* webpackChunkName: "component---gatsby-theme-newscloud-core-src-pages-account-js" */),
  "component---gatsby-theme-newscloud-core-src-pages-checkout-js": () => import("./../../../../gatsby-theme-newscloud-core/src/pages/checkout.js" /* webpackChunkName: "component---gatsby-theme-newscloud-core-src-pages-checkout-js" */),
  "component---gatsby-theme-newscloud-core-src-pages-checkout-success-js": () => import("./../../../../gatsby-theme-newscloud-core/src/pages/checkout-success.js" /* webpackChunkName: "component---gatsby-theme-newscloud-core-src-pages-checkout-success-js" */),
  "component---gatsby-theme-newscloud-core-src-pages-p-post-id-js": () => import("./../../../../gatsby-theme-newscloud-core/src/pages/p/[postId].js" /* webpackChunkName: "component---gatsby-theme-newscloud-core-src-pages-p-post-id-js" */),
  "component---gatsby-theme-newscloud-core-src-pages-unsubscribe-js": () => import("./../../../../gatsby-theme-newscloud-core/src/pages/unsubscribe.js" /* webpackChunkName: "component---gatsby-theme-newscloud-core-src-pages-unsubscribe-js" */),
  "component---gatsby-theme-newscloud-core-src-templates-author-js": () => import("./../../../../gatsby-theme-newscloud-core/src/templates/author.js" /* webpackChunkName: "component---gatsby-theme-newscloud-core-src-templates-author-js" */),
  "component---gatsby-theme-newscloud-core-src-templates-index-js": () => import("./../../../../gatsby-theme-newscloud-core/src/templates/index.js" /* webpackChunkName: "component---gatsby-theme-newscloud-core-src-templates-index-js" */),
  "component---gatsby-theme-newscloud-core-src-templates-newsletter-index-js": () => import("./../../../../gatsby-theme-newscloud-core/src/templates/newsletterIndex.js" /* webpackChunkName: "component---gatsby-theme-newscloud-core-src-templates-newsletter-index-js" */),
  "component---gatsby-theme-newscloud-core-src-templates-page-js": () => import("./../../../../gatsby-theme-newscloud-core/src/templates/page.js" /* webpackChunkName: "component---gatsby-theme-newscloud-core-src-templates-page-js" */),
  "component---gatsby-theme-newscloud-core-src-templates-post-js": () => import("./../../../../gatsby-theme-newscloud-core/src/templates/post.js" /* webpackChunkName: "component---gatsby-theme-newscloud-core-src-templates-post-js" */),
  "component---gatsby-theme-newscloud-core-src-templates-pricing-js": () => import("./../../../../gatsby-theme-newscloud-core/src/templates/pricing.js" /* webpackChunkName: "component---gatsby-theme-newscloud-core-src-templates-pricing-js" */),
  "component---gatsby-theme-newscloud-core-src-templates-tag-js": () => import("./../../../../gatsby-theme-newscloud-core/src/templates/tag.js" /* webpackChunkName: "component---gatsby-theme-newscloud-core-src-templates-tag-js" */)
}

