import React, { useEffect } from 'react';
import { graphql } from "gatsby";

import Layout from "../components/layout";
import PricingTable from "../components/pricingTable";

const Pricing = ({ data, location, pageContext }) => {
  const site = data.allGhostSettings.edges[0].node;
  const page = data.ghostPage;

  console.log(pageContext);


  return (
    <Layout location={location} site={site}>
      <div className="outer">
        <div className="inner">
          <header className="post-full-header">
            <h1 className="post-full-title">{page.title}</h1>
          </header>
          <div style={{paddingBottom: '1em'}} className="post-full-content">
            <div
                className="post-content load-external-scripts"
                dangerouslySetInnerHTML={{ __html: pageContext.pricingIntro }}
            />
          </div>
          <PricingTable plans={pageContext.pricingDetails} />
          <div style={{paddingBottom: '1em', paddingTop: '3em'}} className="post-full-content">
            <div
                className="post-content load-external-scripts"
                dangerouslySetInnerHTML={{ __html: pageContext.pricingConclusion }}
            />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Pricing

export const pageQuery = graphql`
  query($slug: String!) {
    allGhostSettings {
        edges {
            node {
                ...GhostSettingsFields
            }
        }
    }
    ghostPage(slug: { eq: $slug }) {
        ...GhostPageFields
    }
  }
`
