// src/pages/account.js
import React from "react"
import { graphql } from "gatsby"
import { Router } from "@reach/router"
import Layout from "../../components/layout"
import Preview from "../../components/preview"
import PrivateRoute from "../../components/privateRoute"

const PreviewRoute = ({params, data, location }) => {
  const site = data.allGhostSettings.edges[0].node;
  const postId = params['*'];

  return (
    <Layout location={location} site={site} >
      <Router>
        <PrivateRoute path={`/p/${postId}`} component={Preview} location={location} params={params} data={data} site={site} postId={postId} />
      </Router>
    </Layout>
  )
}

export default PreviewRoute

export const pageQuery = graphql`
  query {
    allGhostSettings {
        edges {
            node {
                ...GhostSettingsFields
            }
        }
    }
  }
`
