import React, { useState, useEffect } from 'react';
import { graphql } from "gatsby";
import queryString from "query-string";
import { useIdentityContext } from 'react-netlify-identity-gotrue';

import Layout from "../components/layout";
import LoadingOverlay from "../components/loadingOverlay";

const CheckoutSuccess = ({ data, location }) => {
  const site = data.allGhostSettings.edges[0].node;
  const welcomePage = data.ghostPage;
  const identity = useIdentityContext();


  const queryParams = queryString.parse(location.search);
  const { session_id } = queryParams;

  const [loadingSession, setLoadingSession] = useState(true);
  const [stripeSession, setStripeSession] = useState(null);
  const [updatedNetlify, setUpdatedNetlify] = useState(false);
  const [welcome, setWelcome] = useState(null);


  const handleWelcome = (stripeData) => {
    let splitPlan = stripeData.session.metadata.subscription_level.split('-');
    let level = splitPlan[0].toLowerCase();

    if (typeof window !== `undefined`) {
      const parser = new DOMParser();
      const htmlDoc = parser.parseFromString(welcomePage.html, 'text/html');
      setWelcome(htmlDoc.getElementById(level).nextSibling.outerHTML);
    }

    setLoadingSession(false);

  }



  const getStripeSession = async ( session_id ) => {
    async function postData(url) {
       const response = await fetch(url, {
         method: 'POST',
         mode: 'cors',
         headers: {
           'Content-Type': 'application/json',
         },
         body: JSON.stringify({session_id: session_id})
       });
       return response.json();
     }
    return await postData('/api/getStripeSession/');
  }

  const updateStripeCustomer = async ( customerID, netlifyID ) => {
    async function postData(url) {
       const response = await fetch(url, {
         method: 'POST',
         mode: 'cors',
         headers: {
           'Content-Type': 'application/json',
         },
         body: JSON.stringify({customer_id: customerID, metadata: {netlify_id: netlifyID} })
       });
       return response.json();
     }
    return await postData('/api/updateStripeCustomer/');
  }

  useEffect(() => {
    if (loadingSession && session_id && session_id !== '') {
      getStripeSession(session_id)
        .then(stripeData => {
          setStripeSession(stripeData);
          handleWelcome(stripeData);
        })
        .catch(err => {
          setLoadingSession(false);
        })
    }
  }, [loadingSession, setStripeSession, setLoadingSession, session_id])

  useEffect(() => {
    if (!loadingSession && !updatedNetlify && stripeSession && !stripeSession.customer.metadata.netlify_id && identity.user) {

      let clientId;
      if (typeof window !== `undefined`) {
        var tracker = window.ga.getAll()[0];
        clientId = tracker.get('clientId');
      }

      async function updateMemberData() {
        await updateStripeCustomer(stripeSession.customer.id, identity.user.id);
        identity.authorizedFetch('/api/updateGhostMember/', {
          method: 'POST',
          body: JSON.stringify({
            ghost_id: stripeSession.customer.metadata.ghost_id,
            subscription_level: stripeSession.session.metadata.subscription_level,
            stripe_customer: stripeSession.customer.id,
            full_name: identity.user.user_metadata.full_name,
            clientId: clientId
           })
          })
          .then(identity.refreshUser)
        setUpdatedNetlify(true);
      }
      updateMemberData();
    }
  }, [stripeSession, identity, updatedNetlify, setUpdatedNetlify, loadingSession])


  return (
    <Layout location={location} site={site}>
      <div className="outer">
        <div className="inner">
          {!loadingSession && !welcome &&
            <section style={{textAlign: 'center', paddingTop: '3em'}} className="post-full-content">
            <h2>Thanks for joining us {identity.user.user_metadata.first_name}!</h2>
            <p>You now have access to all our our premium content.</p>
            </section>
          }
          {!loadingSession && welcome &&
            <section style={{textAlign: 'center', paddingTop: '3em'}} className="post-full-content">
              <h2>Thanks for joining us {identity.user.user_metadata.first_name}!</h2>
              <div
                  className="post-content load-external-scripts"
                  dangerouslySetInnerHTML={{ __html: welcome }}
              />
            </section>
          }
        </div>
      </div>
      <LoadingOverlay showOverlay={loadingSession} />
    </Layout>
  )
}

export default CheckoutSuccess

export const pageQuery = graphql`
  query {
    allGhostSettings {
        edges {
            node {
                ...GhostSettingsFields
            }
        }
    }
    ghostPage(tags: {elemMatch: {slug: {eq: "hash-welcome"}}} ) {
        ...GhostPageFields
    }
  }
`
