import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import { Helmet } from 'react-helmet';
import { useIdentityContext } from 'react-netlify-identity-gotrue';

import Sharing from '../components/sharing';
import PostCta from '../components/postCta';

const Preview = ( { location, site, params, postId } ) => {
  const identity = useIdentityContext();
  let postClass = 'post-full';
//  if (noImage) {postClass = postClass + ' no-image'};

  const [postContent, setPostContent] = useState(null);

  useEffect(() => {
    if (!postContent) {
      getDraftPost(postId)
        .then((res) => {
          console.log(res);
          setPostContent(res);
        })
        .catch((e) => {
          console.log(e);
        });
    }

  }, [postContent, setPostContent, postId])


  const getDraftPost = async ( postId ) => {
    async function postData(url) {
       const response = await fetch(url, {
         method: 'POST',
         mode: 'cors',
         headers: {
           'Content-Type': 'application/json',
         },
         body: JSON.stringify({id: postId})
       });
       return response.json();
     }
    return await postData('/api/getDraftPost/');
  }

  if (!postContent) {
    return (
      <div>Loading</div>
    )
  }
  else {

    return (
        <>
            <Helmet>
                <style type="text/css">{`${postContent.codeinjection_styles}`}</style>
            </Helmet>
              <div className="outer">
                <div className="inner">
                    <article className={postClass}>
                      <header className="post-full-header">
                        <h1 className="post-full-title">{postContent.title}</h1>
                        <div className="post-full-byline">
                            <section className="post-full-byline-content">
                                <section className="post-full-byline-meta">
                                    <div className="byline-meta-content">
                                      { postContent.tags.length > 0 ?
                                          <>
                                            <span className="post-full-tags">
                                                {postContent.tags.map( (tag) => {
                                                  return (
                                                  <Link key={tag.slug} to={`/tag/${tag.slug}/`} title={tag.name}>{tag.name}</Link>
                                                )})}
                                            </span>
                                            <span> | </span>
                                          </>
                                      : null }
                                        <span className="byline-meta-date">
                                          {postContent.created_at}
                                        </span>
                                        <span> | By: </span>
                                        {postContent.authors.map( (author, i) => {
                                          if (i > 0) { return <span key={author.slug}>, <Link to={`/author/${author.slug}/`} title={author.name}>{author.name}</Link></span> }
                                          else { return <Link key={author} to={`/author/${author.slug}/`} title={author.name}>{author.name}</Link> }
                                          })}

                                    </div>
                                </section>
                            </section>
                        </div>
                        <Sharing url={postContent.url} title={postContent.title} featuredImage={postContent.feature_image} />
                      </header>

                      { postContent.feature_image ?
                          <figure className="post-full-image">
                              <img src={ postContent.feature_image } alt={ postContent.title } />
                          </figure> : null }

                      <section className="post-full-content">
                          <section
                              className="post-content load-external-scripts"
                              dangerouslySetInnerHTML={{ __html: postContent.html }}
                          />
                      </section>
                      {postContent.visibility !== 'public' && identity.user?.app_metadata.roles && identity.user.app_metadata.roles[0] === 'paid' ? <></> :
                        <PostCta title={site.title} description={site.description} />
                      }
                    </article>
                </div>
              </div>
        </>
    )
  }
}

export default Preview;
