import * as React from "react";
import PropTypes from 'prop-types';
import { graphql } from "gatsby";

import Layout from "../components/layout";
import Hero from "../components/hero";
import PostCard from '../components/postCard';
import MetaData from '../components/meta/metaData';

const Index = ({ data, location }) => {
  const site = data.allGhostSettings.edges[0].node;
  const posts = data.allGhostPost.edges;

  //Get two latest Featured posts
  const featuredPosts = posts.filter(function(post) {
    if (this.count < 2 && post.node.featured === true) {
      this.count++;
      return true;
    }
    return false;
  }, {count: 0});
  //Get 4 latest non-Featured posts
  const otherPosts = posts.filter(function(post) {
    if (this.count < 4 && post.node.featured === false) {
      this.count++;
      return true;
    }
    return false;
  }, {count: 0});
  const firstOtherPosts = otherPosts.slice(0, 2);
  const secondOtherPosts = otherPosts.slice(2);


  return (
    <>
    <MetaData location={location} />
    <Layout location={location} site={site}>
      <Hero site={site}/>
      <div className="outer">
          <section className="inner posts">
            <div className="post-feed">
              {featuredPosts.length > 0 && <PostCard key={featuredPosts[0].node.id} post={featuredPosts[0].node} large /> }
              {firstOtherPosts.map(({ node }) => (
                  <PostCard key={node.id} post={node} />
              ))}
              {featuredPosts.length > 1 && <PostCard key={featuredPosts[1].node.id} post={featuredPosts[1].node} large /> }
              {secondOtherPosts.map(({ node }) => (
                  <PostCard key={node.id} post={node} />
              ))}

            </div>

          </section>
      </div>
    </Layout>
  </>
  )
}


Index.propTypes = {
    data: PropTypes.shape({
        allGhostPost: PropTypes.object.isRequired,
    }).isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
    pageContext: PropTypes.object,
}

export default Index

// This page query loads all posts sorted descending by published date
// The `limit` and `skip` values are used for pagination
export const pageQueryCore = graphql`
  query GhostPostQueryCore($limit: Int!, $skip: Int!, $exclude: [String!]) {
    allGhostPost(
        sort: { order: DESC, fields: [published_at] },
        filter: {id: {nin: $exclude}},
        limit: $limit,
        skip: $skip
    ) {
      edges {
        node {
          ...GhostPostFields
        }
      }
    }
    allGhostSettings {
        edges {
            node {
                ...GhostSettingsFields
            }
        }
    }

  }
`
