module.exports = [{
      plugin: require('../../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":630,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"sizeByPixelDensity":false,"backgroundColor":"white","quality":50,"withWebp":false,"tracedSVG":false,"loading":"lazy","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../../node_modules/gatsby-plugin-netlify-identity-gotrue/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://condescending-roentgen-53f5ec.netlify.app"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../gatsby-theme-newscloud-core/gatsby-browser.js'),
      options: {"plugins":[],"ghostApiUrl":"https://cloud.epilocal.com","ghostContentApiKey":"30c95246e659cc4777504a4f84","netlifySiteUrl":"https://condescending-roentgen-53f5ec.netlify.app","shortTitle":"Newsliner-Gatsby","backgroundColor":"#e9e9e9","postsPerPage":6,"siteTitleMeta":"Newsliner for Gatsby","siteDescriptionMeta":"A starter template to build static websites with Ghost and Gatsby using the Newsliner theme by Epilocal","shareImageWidth":1000,"shareImageHeight":523},
    },{
      plugin: require('../../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-T359RQ3","includeInDevelopment":true,"routeChangeEventName":"gatsbyRouteChange","defaultDataLayer":null,"enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
