import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { useIdentityContext } from 'react-netlify-identity-gotrue';

import Navigation from './navigation';
import LoginButton from './loginButton';
import Button from './button';

import iconFacebook from '../images/icons/facebook.svg';
import iconTwitter from '../images/icons/twitter.svg';
import iconRss from '../images/icons/rss.svg';

import "./styles/header.css";

const Header = ({ children, site, facebookUrl, twitterUrl, toggleMobileMenu, showLoginPopup, setShowLoginPopup, showRegisterPopup, setShowRegisterPopup }) => {
  const identity = useIdentityContext();

  const data = useStaticQuery(graphql`
    query HeaderQueryCore {
      site {
        siteMetadata {
          siteUrl
        }
      }
      ghostPage(tags: {elemMatch: {slug: {eq: "hash-pricing"}}} ) {
          ...GhostPageFields
      }
    }
  `)

  return (
    <div className="outer site-nav-main">
        <div className="inner">
          <div className="site-nav-main-container">
            <div className="site-nav-container-small">
              <div className="site-nav-left">
                  {site.secondary_navigation.length > 0 ?
                    <Navigation data={site.secondary_navigation} />
                      :
                      <>
                      <div className="social-links">
                        { site.facebook && <a href={ facebookUrl } className="social-link social-link-tw" target="_blank" rel="noopener noreferrer"><img className="site-nav-icon" src={iconFacebook} alt="Facebook" /></a>}
                        { site.twitter && <a href={ twitterUrl } className="social-link social-link-fb" target="_blank" rel="noopener noreferrer"><img className="site-nav-icon" src={iconTwitter} alt="Twitter" /></a>}
                      </div>
                        <a className="rss-button" href={ `https://feedly.com/i/subscription/feed/${data.site.siteMetadata.siteUrl}/rss/` } target="_blank" rel="noopener noreferrer"><img className="site-nav-icon" src={iconRss} alt="RSS Feed" /></a>
                      </>
                  }
              </div>
            </div>
            <div className="site-nav-container-large">
              <h1 className="site-logo-title">
                <Link to="/">
                    {site.logo ?
                        <img className="site-logo" src={site.logo} alt={site.title} />
                        : <span>{site.title}</span>
                    }
                </Link>
              </h1>
            </div>
            <div className="site-nav-container-small">
              <div className="site-nav-right">
                {!identity.user &&
                  <>
                  <Button
                    textSize='xl'
                    customMargin={'0 10px'}
                    opposite
                     ><Link to={`/${data.ghostPage.slug}/`}>Join Us</Link></Button>
                    <LoginButton showPopup={showLoginPopup} setShowPopup={setShowLoginPopup}>Login</LoginButton>
                  </>
                }
                {identity.user &&
                  <>
                  <Button
                    textSize='xl'
                    customMargin={'0 10px'}
                    opposite
                     ><Link to="/account/profile">{identity.user.email}</Link></Button>
                  <Button
                    textSize='xl'
                    opposite
                    onClick={identity.logout}
                    onKeyDown={identity.logout}
                     >Logout</Button>
                  </>
                }

                  {/*
                  <a className="subscribe-button" href="#/portal" >{`Member email`}</a>
                  */}
              </div>
            </div>
          </div>
          <div className="site-nav-container">
            <nav className="site-nav">
                <div className="site-nav-center-wrapper">
                    <div className="site-nav-center">
                        <div className="site-nav-content">
                          {site.navigation && <Navigation data={site.navigation} navclassName="site-nav-item" />}
                        </div>
                    </div>
                </div>
            </nav>

          </div>
        </div>
    </div>
)
}

Header.propTypes = {
    children: PropTypes.node,
    site: PropTypes.object,
    twitterUrl: PropTypes.string,
    facebookUrl: PropTypes.string
}



export default Header
