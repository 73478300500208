import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import PostCard from '../components/postCard';
import Pagination from '../components/pagination';
import MetaData from '../components/meta/metaData';

/**
* Tag page (/tag/:slug)
*
* Loads all posts for the requested tag incl. pagination.
*
*/
const Tag = ({ data, location, pageContext }) => {
    const tag = data.ghostTag;
    const posts = data.allGhostPost.edges;
    const site = data.allGhostSettings.edges[0].node;

    return (
        <>
            <MetaData
                data={data}
                location={location}
                type="series"
            />
            <Layout location={location} site={site}>
              <div className="outer site-archive">
                  <header className="inner">
                    <h1 className="site-title">{tag.name}</h1>
                    <p className="site-description">{tag.description}</p>
                  </header>
                  <section className="inner posts">
                    <div className="post-feed">
                      {posts.map(({ node }) => (
                          // The tag below includes the markup for each post - components/common/PostCard.js
                          <PostCard key={node.id} post={node} large />
                      ))}
                    </div>

                  </section>
                  <Pagination pageContext={pageContext} />
              </div>
            </Layout>
        </>
    )
}

Tag.propTypes = {
    data: PropTypes.shape({
        ghostTag: PropTypes.shape({
            name: PropTypes.string.isRequired,
            description: PropTypes.string,
        }),
        allGhostPost: PropTypes.object.isRequired,
    }).isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
    pageContext: PropTypes.object,
}

export default Tag

export const pageQuery = graphql`
    query GhostTagQuery($slug: String!, $limit: Int!, $skip: Int!) {
        allGhostSettings {
            edges {
                node {
                    ...GhostSettingsFields
                }
            }
        }
        ghostTag(slug: { eq: $slug }) {
            ...GhostTagFields
        }
        allGhostPost(
            sort: { order: DESC, fields: [published_at] },
            filter: {tags: {elemMatch: {slug: {eq: $slug}}}},
            limit: $limit,
            skip: $skip
        ) {
            edges {
                node {
                ...GhostPostFields
                }
            }
        }
    }
`
