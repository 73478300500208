import React  from 'react';
import { Link } from "gatsby"

import "./styles/pricingTable.css";


const PricingTable = ({ children, plans }) => {

  return (
    <div className="comparison">
      {plans &&
      <table>
        <thead>
          <tr>
            <th className="compare-heading">&nbsp;</th>
            {plans.map( (plan) => (
              <th key={`Description-${plan['Name']}`} className="compare-heading">{plan['Name']}</th>
            ))}
          </tr>
          <tr className="monthly">
            <th>MONTHLY</th>
            {plans.map( (plan) => (
              <th key={`Monthly-${plan['Name']}`} className="price-info">
                <div><span className="price">{plan['Monthly Price']}</span><span className="price-small"> /mo</span></div>
                <div><Link to={`/checkout/?plan=${plan['Monthly Stripe ID']}&type=${plan['Name']}-Monthly`} className="join-button">Join <span className="hide-mobile">Now</span></Link></div>
              </th>
            ))}
          </tr>
          <tr className="annual">
            <th>ANNUAL</th>
              {plans.map( (plan) => (
                <th key={`Annual-${plan['Name']}`} className="price-info">
                <div><span className="price">{plan['Annual Price']}</span><span className="price-small"> /yr</span></div>
                <div><Link to={`/checkout/?plan=${plan['Annual Stripe ID']}&type=${plan['Name']}-Annual`} className="join-button">Join <span className="hide-mobile">Now</span></Link></div>
                </th>
              ))}
          </tr>
      </thead>
      <tbody>
        {plans.map( (plan, i) => (
          plan['Benefits'].map( (benefit) => (
            <>
            <tr key={`mobile-${benefit}`}>
              <td>&nbsp;</td>
              <td className="description-mobile" colSpan={plans.length}>{benefit}</td>
            </tr>
            <tr key={`${benefit}`}>
              <td className="description">{benefit}</td>
              {plans.map( (tickCell, tickCellNum) => {
                  if (tickCellNum >= i) {
                    return (
                      <td key={`${benefit}-${tickCellNum}`} className="tickcell">✔</td>
                    )
                  }
                  else {
                    return (
                      <td key={`${benefit}-${tickCellNum}`} className="dashcell">-</td>
                    )
                  }
                }
              )}
            </tr>
          </>
          ))
        ))}
      </tbody>
      </table>
      }
      </div>

  );
};

export default PricingTable;
