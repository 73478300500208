import * as React from "react";
import PropTypes from 'prop-types';
import { graphql } from "gatsby";

import Layout from "../components/layout";
import PostCard from '../components/postCard';
import MetaData from '../components/meta/metaData';

const Index = ({ data, location }) => {
  const site = data.allGhostSettings.edges[0].node;
  const posts = data.allGhostPost.edges;


  return (
    <>
    <MetaData location={location} />
    <Layout location={location} site={site}>
      <div className="outer site-archive">
        <header className="inner">
          <h1 className="site-title">Newsletter Archive</h1>
        </header>
          <section className="inner posts">
            <div className="post-feed">
              {posts.map(({ node }) => (
                  <PostCard key={node.id} post={node} />
              ))}
            </div>
          </section>
      </div>
    </Layout>
  </>
  )
}


Index.propTypes = {
    data: PropTypes.shape({
        allGhostPost: PropTypes.object.isRequired,
    }).isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
    pageContext: PropTypes.object,
}

export default Index

// This page query loads all posts sorted descending by published date
// The `limit` and `skip` values are used for pagination
export const pageQuery = graphql`
  query GhostNewsletterPostQuery($limit: Int!, $skip: Int!) {
    allGhostPost(
        sort: { order: DESC, fields: [published_at] },
        filter: {tags: {elemMatch: {slug: {eq: "hash-newsletter"}}}},
        limit: $limit,
        skip: $skip
    ) {
      edges {
        node {
          ...GhostPostFields
        }
      }
    }
    allGhostSettings {
        edges {
            node {
                ...GhostSettingsFields
            }
        }
    }

  }
`
