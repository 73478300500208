import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql, Link } from 'gatsby';

import SubscribeForm from './subscribeForm';
import Button from './button';


const PostCta = ( { title, description, type } ) => {

  const data = useStaticQuery(graphql`
    query PostCtaCore {
      site {
        siteMetadata {
          siteUrl
        }
      }
      ghostPage(tags: {elemMatch: {slug: {eq: "hash-pricing"}}} ) {
          ...GhostPageFields
      }
    }
  `)

  if (type === "newsletter") {
    return (
      <section className="post-full-content">
        <div className="post-cta-inner">
          <h3 className="subscribe-form-title-alternate">Subscribe to {title}</h3>
          <p className="subscribe-form-description-alternate">{description ? description : 'Get the latest posts delivered right to your inbox'}</p>
          <SubscribeForm />
        </div>
      </section>
    )
  }
  else {
    return (
      <section className="post-full-content">
        <div className="post-cta-inner">
          <h3 style={{paddingBottom: '1em'}} className="subscribe-form-title-alternate">This article is for paid members only.</h3>
          <Button
            textSize='xl'
            customMargin={'0 10px'}
            opposite
             ><Link style={{boxShadow: 'none'}} to={`/${data.ghostPage.slug}/`}>Join Now</Link></Button>
        </div>
      </section>
    )
  }

}


PostCta.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string
}

export default PostCta
