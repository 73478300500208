import React, { useEffect } from 'react';
import { graphql } from "gatsby";
import queryString from "query-string";

import Layout from "../components/layout";

const Unsubscribe = ({ data, location }) => {
  const site = data.allGhostSettings.edges[0].node;

  const queryParams = queryString.parse(location.search);
  const { id } = queryParams;


  const updateGhostSubscription = async ( ghostID ) => {
    async function postData(url) {
       const response = await fetch(url, {
         method: 'POST',
         mode: 'cors',
         headers: {
           'Content-Type': 'application/json',
         },
         body: JSON.stringify({ghost_id: ghostID, subscribed: false})
       });
       return response.json();
     }
    return await postData('/api/updateGhostSubscription/');
  }

  useEffect(() => {
    if (id) {
      updateGhostSubscription(id);
    }
    else {
      if (typeof window !== `undefined`) {
        window.location.href = '/';
      }
    }
  }, [id])

  return (
    <Layout location={location} site={site}>
      <div className="outer">
        <div className="inner">
          <section className="blog-wrapper" style={{textAlign: 'center', padding: '10rem 0'}}>
            <h2>You have been unsubscribed from our newsletter.</h2>
            <p>You will no longer receive any emails from us.</p>
          </section>
        </div>
      </div>
    </Layout>
  )
}

export default Unsubscribe

export const pageQuery = graphql`
  query {
    allGhostSettings {
        edges {
            node {
                ...GhostSettingsFields
            }
        }
    }
  }
`
