import React from 'react';

import "./styles/button.css";

const sizes = {
  default: `buttonDefault`,
  lg: `buttonLg`,
  xl: `buttonXl`
};

const fontSizes = {
  default: `1rem`,
  sm: `0.8rem`,
  md: `1.1rem`,
  lg: `1.3rem`,
  xl: `1.5rem`
};




const Button = ({ children, textSize = '', size, onClick, opposite, outline, customMargin, outlineNoHover, customType, disabled }) => {
  return (
    <button
      style={{
            fontSize: `${fontSizes[textSize] || fontSizes.default}`,
            fontFamily: 'var(--font-heading)',
            margin: customMargin,
            whiteSpace: 'nowrap'
          }}
      type={`${customType ? customType : 'button'}`}
      disabled={disabled}
      onClick={onClick}
      className={`${sizes[size] || sizes.default} ${opposite ? `buttonOpposite` : ''} ${outline ? `buttonOutline` : ``}${outlineNoHover ? `buttonOutlineNoHover` : ``}${!outlineNoHover && !outline && !opposite ? `button` : ``}`}
    >
      {children}
    </button>
  );
};

export default Button;
