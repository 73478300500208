import React from 'react';

import Loader from "../svg/loader";

import './styles/loadingOverlay.css';

const LoadingOverlay = ( { children, showOverlay } ) => {


  return (
    <>
      {showOverlay ? (
        <div className="overlay-container">
          <div className="overlay-background">
            <div className="overlay-loader"><Loader/></div>
            </div>
        </div>
      ) : null}
    </>
  )
}
export default LoadingOverlay
