import React from 'react';

import Button from './button';

import { handleKeyDown } from '../utils/utils';

const LoginButton = ({ children, showPopup, setShowPopup, user, customMargin, link, linkClick, linkKeyDown }) => {

  const handleLoginClick = () => {
    if (!user) {
      setShowPopup(!showPopup);
    }
  }

  const handleLoginKeyDown = (e) => {
    if (!user) {
      handleKeyDown(e, showPopup, setShowPopup);
    }
  }

  if (link) {
    return (
      <button className="link" onClick={linkClick ? linkClick : (e) => handleLoginClick()} onKeyDown={linkKeyDown ? linkKeyDown : (e) => handleLoginKeyDown(e)}>{children}</button>
    )
  }
  else {
    return (
      <Button
        opposite
        textSize='xl'
        customMargin={customMargin}
        onClick={ (e) => handleLoginClick() }
        onKeyDown={ (e) => handleLoginKeyDown(e) }
         >{children}</Button>

    )
  }


}

export default LoginButton;
