import * as React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";

const NotFoundPage = ({ data, location }) => {
  const site = data.allGhostSettings.edges[0].node;

  return (
    <Layout location={location} site={site}>
      <h1>404: Not Found</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </Layout>
  )
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    allGhostSettings {
        edges {
            node {
                ...GhostSettingsFields
            }
        }
    }
  }
`
