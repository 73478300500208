import React, { useState } from 'react';
import { graphql } from "gatsby";
import queryString from "query-string";
import { useIdentityContext } from 'react-netlify-identity-gotrue';
import { Link } from "gatsby";

import Layout from "../components/layout";
import RegisterForm from "../components/registerForm";
import LoadingOverlay from "../components/loadingOverlay";
import Button from '../components/button';

const CheckoutSuccess = ({ data, location }) => {
  const site = data.allGhostSettings.edges[0].node;
  const siteUrl = data.site.siteMetadata.siteUrl;
  const identity = useIdentityContext();

  const queryParams = queryString.parse(location.search);
  const { plan, type } = queryParams;

  const [loadingCheckout, setLoadingCheckout] = useState(false);

  const startCheckout = async ( selectedPlan, email, planType ) => {
    setLoadingCheckout(true);
    async function postData(url) {
       const response = await fetch(url, {
         method: 'POST',
         mode: 'cors',
         headers: {
           'Content-Type': 'application/json',
         },
         body: JSON.stringify({plan: selectedPlan, email: email, type: planType, siteUrl: siteUrl })
       });
       return response.json()
     }
    return await postData('/api/checkout').then(
      (link) => {
        window.location.href = link;
      }
    );
  }



  return (
    <Layout location={location} site={site}>
      <div className="outer">
        <div className="inner">
          {identity.user && !loadingCheckout &&
            <>
            <h2>Thanks for joining us!</h2>
            {
              identity.user.app_metadata?.roles && identity.user.app_metadata.roles[0]  === 'paid' ?
              <>
                <h3>You already have a valid subscription and can manage your account here</h3>
                <Button><Link to="/account/profile">Manage Account</Link></Button>
              </>

              :
              <>
                <h3>You just still need to complete your payment:</h3>
                <Button onClick={() => startCheckout(plan, identity.user.email, type)}>Complete Payment</Button>
              </>

            }

            </>
          }
          {!identity.user &&
            <>
              <div style={{textAlign: 'center'}}>
                <h2>Thanks for joining us!</h2>
                <h3>Please provide the following details:</h3>
              </div>

              <RegisterForm loginType='register' checkout={startCheckout} plan={plan} type={type} />
            </>
          }

        </div>
      </div>
      <LoadingOverlay showOverlay={loadingCheckout} />
    </Layout>
  )
}

export default CheckoutSuccess

export const pageQuery = graphql`
  query {
    allGhostSettings {
        edges {
            node {
                ...GhostSettingsFields
            }
        }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`
