import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { graphql, Link } from 'gatsby';
import { Helmet } from 'react-helmet';
import { useIdentityContext } from 'react-netlify-identity-gotrue';

import Layout from '../components/layout';
import Sharing from '../components/sharing';
import PostCta from '../components/postCta';
import PostCard from '../components/postCard';
import MetaData from '../components/meta/metaData';

/**
* Single post view (/:slug)
*
* This file renders a single post and loads all the content.
*
*/
const Post = ({ data, location, noImage }) => {
    const post = data.ghostPost;
    const site = data.allGhostSettings.edges[0].node;
    const relatedPosts = data.allGhostPost.edges;
    let postClass = 'post-full';
    if (noImage) {postClass = postClass + ' no-image'};
    const identity = useIdentityContext();

    const [postContent, setPostContent] = useState(post.html);
    const [gtmDataSent, setGtmDataSent] = useState(false);

    let subd = false;
    if (typeof window !== `undefined`) {
      subd = localStorage.getItem('epi.subd');
    }

    useEffect(() => {
      if (post.visibility !== 'public' && identity.user?.app_metadata.roles && identity.user.app_metadata.roles[0] === 'paid') {
        getPremiumContent(post.slug)
          .then((res) => {
            setPostContent(res.html);
          })
          .catch((e) => {
            console.log(e);
          });
      }

      if (post.visibility !== 'public' && !identity.user) {
        setPostContent(post.html);
      }

    }, [post, identity.user, setPostContent])

  useEffect(() => {
    if (!gtmDataSent && typeof window !== 'undefined' && post.primary_tag && post.primary_author){
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({ postTag: post.primary_tag.name, postAuthor: post.primary_author.name  });
      setGtmDataSent(true);
    }
  }, [gtmDataSent]);

    const getPremiumContent = async ( slug ) => {
      async function postData(url) {
         const response = await fetch(url, {
           method: 'POST',
           mode: 'cors',
           headers: {
             'Content-Type': 'application/json',
           },
           body: JSON.stringify({slug: slug})
         });
         return response.json();
       }
      return await postData('/api/getPremiumContent/');
    }

    return (
        <>
            <MetaData
                data={data}
                location={location}
                type="article"
            />
            <Helmet>
                <style type="text/css">{`${post.codeinjection_styles}`}</style>
            </Helmet>
            <Layout location={location} site={site} post>
              <div className="outer">
                <div className="inner">
                    <article className={postClass}>
                      <header className="post-full-header">
                        <h1 className="post-full-title">{post.title}</h1>
                        <div className="post-full-byline">
                            <section className="post-full-byline-content">
                                <section className="post-full-byline-meta">
                                    <div className="byline-meta-content">
                                        { post.primary_tag &&
                                          <>
                                          <span className="post-full-tags">
                                            <Link key={post.primary_tag.slug} to={`/tag/${post.primary_tag.slug}/`} title={post.primary_tag.name}>{post.primary_tag.name}</Link>
                                          </span>
                                          <span> | </span>
                                          </>
                                        }
                                        <span className="byline-meta-date">
                                          {post.created_at_pretty}
                                        </span>
                                        <span> | By: </span>
                                        {post.authors.map( (author, i) => {
                                          if (i > 0) { return <span key={author.slug}>, <Link to={`/author/${author.slug}/`} title={author.name}>{author.name}</Link></span> }
                                          else { return <Link key={author} to={`/author/${author.slug}/`} title={author.name}>{author.name}</Link> }
                                          })}

                                    </div>
                                </section>
                            </section>
                        </div>
                        <Sharing url={post.url} title={post.title} featuredImage={post.feature_image} />
                      </header>

                      { post.feature_image ?
                          <figure className="post-full-image">
                              <img src={ post.feature_image } alt={ post.title } />
                          </figure> : null }

                      <section className="post-full-content">
                          <section
                              className="post-content load-external-scripts"
                              dangerouslySetInnerHTML={{ __html: postContent }}
                          />
                      </section>
                      {post.visibility === 'public' && !identity?.user && !subd &&
                        <PostCta title={site.title} description={site.description} type="newsletter"/>
                      }
                      {post.visibility !== 'public' && (!identity?.user?.app_metadata?.roles || identity.user.app_metadata.roles[0] !== 'paid') &&
                        <PostCta title={site.title} description={site.description} type="paid"/>
                      }
                      {relatedPosts.length > 0 &&
                      <section className="related-posts-container">
                          <h3 className="related-posts-title"><span className="text">You might also like...</span></h3>
                            <div className="post-feed">
                              {relatedPosts.map( ({ node }) => (
                                <PostCard key={node.id} post={node} />
                              ))}
                          </div>
                      </section> }
                    </article>
                </div>
              </div>
            </Layout>
        </>
    )
}

Post.propTypes = {
    data: PropTypes.shape({
        ghostPost: PropTypes.shape({
            codeinjection_styles: PropTypes.object,
            title: PropTypes.string.isRequired,
            html: PropTypes.string.isRequired,
            feature_image: PropTypes.string,
        }).isRequired,
    }).isRequired,
    location: PropTypes.object.isRequired,
}

export default Post

export const postQuery = graphql`
    query ($slug: String!, $primary_tag: String!) {
        allGhostSettings {
            edges {
                node {
                    ...GhostSettingsFields
                }
            }
        }
        ghostPost(slug: { eq: $slug }) {
            ...GhostPostFields
        }
        allGhostPost(
            sort: { order: DESC, fields: [published_at] },
            filter: {tags: {elemMatch: {slug: {eq: $primary_tag}}}, slug: {ne: $slug }},
            limit: 2
        ) {
            edges {
                node {
                ...GhostPostFields
                }
            }
        }
    }
`
