import React, { useState } from 'react';
import { useIdentityContext } from 'react-netlify-identity-gotrue';
import { Form, Field } from 'react-final-form';

import Button from './button';
import Loader from "../svg/loader";

import "./styles/memberForm.css";


const RegisterForm = ({ children, loginType, setShowPopup, checkout, plan, defaultSuccessMessage, defaultErrorMessage, type }) => {
  const identity = useIdentityContext();
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState(defaultSuccessMessage);
  const [errorMessage, setErrorMessage] = useState(defaultErrorMessage);
  const [showEmailTaken, setShowEmailTaken] = useState(false);
  const [showInvalidPassword, setShowInvalidPassword] = useState(false);
  const [currentLoginType, setCurrentLoginType] = useState(loginType)


  const onSubmit = (values, form) => {
    setShowLoading(true);

    switch (currentLoginType) {
      case 'register':
        handleSignup(values, form);
        break;
      case 'login':
        handleLogin(values, form);
        break;
      case 'reset':
        handleReset(values, form);
        break;
      case 'update':
        handleUpdate(values, form);
        break;
      default:
        handleLogin(values, form);
    }
  }

  const handleSignup = async ( values, form ) => {
    let newUser = {
      email: values.username,
      password: values.password,
      user_metadata: {
        first_name: values.firstName,
        last_name: values.lastName,
        full_name: values.firstName + ' ' + values.lastName
      }
    }

    await identity.signup(newUser)
    .then(() => {
      if(checkout && plan) {
        setShowLoading(false);
        checkout(plan, newUser.email, type);
      }
      else {
        setShowSuccessMessage(true);
        //if(setShowPopup) {setShowPopup(false)};
      }

    })
    .catch((e) => {
      setShowLoading(false);
      if (e == "Error: A user with this email address has already been registered") {
        setShowEmailTaken(true);
      }
      else {
        setShowErrorMessage(true);
      }
    });

  }



  const handleLogin = async ( values, form ) => {
    await identity.login({
      email: values.username,
      password: values.password
    })
    .then(() => {
      setShowLoading(false);
      if(setShowPopup) {setShowPopup(false)};
    })
    .catch((e) => {
      setShowLoading(false);
      if (e == "Error: No user found with that email, or password invalid.") {
        setShowInvalidPassword(true);
      }
      else {
        setShowErrorMessage(true);
      }
    });

  }

  const handleUpdate = async ( values, form ) => {
    await identity.update({
      password: values.password
    })
    .then(() => {
      setShowLoading(false);
      setSuccessMessage('Your password has been successfully changed');
      setShowSuccessMessage(true);
    })
    .catch((e) => {
      setShowLoading(false);
      setShowErrorMessage(true);
    });

  }

  const handleReset = async ( values, form ) => {
    await identity.sendPasswordRecovery({
      email: values.username
    })
  .then(() => {
    setShowLoading(false);
    setSuccessMessage('Please check your email for a login link');
    setShowSuccessMessage(true);
  })
  .catch((e) => {
    setShowLoading(false);
    setShowErrorMessage(true);
  });

  }



  return (

    <div className="member-form">
      {showSuccessMessage && <div className="message-success">{successMessage}</div> }
      {showErrorMessage && <div className="message-error">{errorMessage}</div> }
      {!showSuccessMessage && !showErrorMessage &&
      <Form
        onSubmit={ onSubmit }
        initialValues={{}}
        validate={values => {
          const errors = {};
          if (currentLoginType !== 'update') {
            if (!values.username) { errors.username = "Required" };
          }
          if (currentLoginType === 'register') {
            if (!values.firstName) { errors.firstName = "Required" };
            if (!values.lastName) { errors.lastName = "Required" };
          }
          if (currentLoginType !== 'reset') {
            if (!values.password) { errors.password = "Required" };
          }
          if (currentLoginType !== 'login' && currentLoginType !== 'reset') {
            if (!values.confirm) {
              errors.confirm = "Required";
            } else if (values.confirm !== values.password) {
              errors.confirm = "Passwords Must Match";
            }
          }
          return errors;
        }}
        render={({ handleSubmit, form, submitting, pristine, values }) => (
          <form onSubmit={handleSubmit}>
            {(currentLoginType === 'register' || currentLoginType === 'login' || currentLoginType === 'reset') &&
            <div className="member-form-group">
              <Field name="username">
                {({ input, meta }) => (
                  <div>
                    <label htmlFor="username">Email</label>
                    <input {...input} type="email" className={meta.error && meta.touched ? 'input-highlight member-input' : 'member-input'} placeholder="youremail@example.com" />
                    {meta.error && meta.touched && <div style={{textAlign: 'center', marginBottom:'-24px', color: 'red', fontStyle: 'italic'}}><span>{meta.error}</span></div>}
                  </div>
                )}
              </Field>
            </div>}
            {(currentLoginType === 'register') &&
            <div className="member-form-group">
              <Field name="firstName">
                {({ input, meta }) => (
                  <div>
                    <label htmlFor="firstName">First Name</label>
                    <input {...input} type="text" className={meta.error && meta.touched ? 'input-highlight member-input' : 'member-input'} placeholder="First Name" />
                    {meta.error && meta.touched && <div style={{textAlign: 'center', marginBottom:'-24px', color: 'red', fontStyle: 'italic'}}><span>{meta.error}</span></div>}
                  </div>
                )}
              </Field>
            </div>}
            {(currentLoginType === 'register') &&
            <div className="member-form-group">
              <Field name="lastName">
                {({ input, meta }) => (
                  <div>
                    <label htmlFor="lastName">Last Name</label>
                    <input {...input} type="text" className={meta.error && meta.touched ? 'input-highlight member-input' : 'member-input'} placeholder="Last Name" />
                    {meta.error && meta.touched && <div style={{textAlign: 'center', marginBottom:'-24px', color: 'red', fontStyle: 'italic'}}><span>{meta.error}</span></div>}
                  </div>
                )}
              </Field>
            </div>}
            {currentLoginType !== 'reset' &&
            <div className="member-form-group">
              <Field name="password">
                {({ input, meta }) => (
                  <div>
                    {(currentLoginType === 'register' || currentLoginType === 'login') ? <label htmlFor="password">Password</label> : <label htmlFor="password">New Password</label> }
                    <input {...input} type="password" className={meta.error && meta.touched ? 'input-highlight member-input' : 'member-input'} placeholder="Password" />
                    {meta.error && meta.touched && <div style={{textAlign: 'center', marginBottom:'-24px', color: 'red', fontStyle: 'italic'}}><span>{meta.error}</span></div>}
                  </div>
                )}
              </Field>
            </div>
            }
            {currentLoginType !== 'login' && currentLoginType !== 'reset' &&
            <div className="member-form-group">
              <Field name="confirm">
                {({ input, meta }) => (
                  <div >
                    {currentLoginType === 'register' && <label htmlFor="confirm">Confirm Password</label> }
                    {currentLoginType === 'update' && <label htmlFor="confirm">Confirm New Password</label> }
                    <input {...input} type="password" className={meta.error && meta.touched ? 'input-highlight member-input' : 'member-input'} placeholder="Confirm Password" />
                    {meta.error && meta.touched && <div style={{textAlign: 'center', marginBottom:'-24px', color: 'red', fontStyle: 'italic'}}><span>{meta.error}</span></div>}
                  </div>
                )}
              </Field>
            </div>
            }
            <div className="member-form-group" style={{textAlign: 'center', paddingTop: '2rem'}}>
              <Button size='xl' textSize='xl' customType="submit" disabled={submitting || pristine}>
                {showLoading ? <span className="button-loader"><Loader/></span> :
                <span className="button-content">
                  {currentLoginType === 'register' && 'Continue to Payment'}
                  {currentLoginType === 'login' && 'Login'}
                  {currentLoginType === 'reset' && 'Send Login Email'}
                  {currentLoginType !== 'login' && currentLoginType !== 'register' && currentLoginType !== 'reset' && 'Update'}
                </span>}
              </Button>
            </div>
            {currentLoginType === 'login' &&
            <div className="member-form-group" style={{textAlign: 'center'}}>
              <p><button className="link" onClick={() => setCurrentLoginType('reset')}>Forgot password?</button></p>
            </div>}
            {currentLoginType === 'reset' &&
            <div className="member-form-group" style={{textAlign: 'center'}}>
              <p><button className="link" onClick={() => setCurrentLoginType('login')}>Login with password</button></p>
            </div>}
            {showEmailTaken &&
              <div className="message-error" style={{color:'red', fontStyle:'italic'}}>
                That email address is already registered as a member
              </div>}
            {showInvalidPassword &&
              <div className="message-error" style={{color:'red', fontStyle:'italic'}}>
                  Invalid Email or Password
              </div> }
            <div style={{margin: '0.5rem'}}>
              <label htmlFor="hp-check" style={{display: 'none'}}>
                <Field
                  name="hp-check"
                  component="input"
                  type="checkbox"
                  validate={value => (value ? "Don't Check" : undefined )}
                  value="checked"
                />
              </label>
            </div>
      </form>
    )} /> }

    </div>
)

}

RegisterForm.defaultProps = {
  defaultSuccessMessage: 'Thanks! You are now registered as an Epilocal member and have access to all of our free resources - enjoy',
  defaultErrorMessage: 'Sorry, something went wrong on our side - please try again later or send a message on our Contact Us page'
};


export default RegisterForm;
