import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import PropTypes from 'prop-types';
import { useIdentityContext } from 'react-netlify-identity-gotrue';

import LoginButton from './loginButton';



const MobileMenu = ({ children, active, toggleMobileMenu, navItems, showLoginPopup, setShowLoginPopup, showRegisterPopup, setShowRegisterPopup }) => {
  let navClass = "site-nav-mobile-menu";
  if (active) { navClass += " active" };
  const identity = useIdentityContext();

  const data = useStaticQuery(graphql`
    query MobileMenuQueryCore {
      site {
        siteMetadata {
          siteUrl
        }
      }
      ghostPage(tags: {elemMatch: {slug: {eq: "hash-pricing"}}} ) {
          ...GhostPageFields
      }
    }
  `)

  return (
    <nav id="site-nav-mobile" className={navClass}>
      <div className="site-nav-mobile-menu-content">
        <ul className="nav">
            <li  key={'mobile-nav-home'}><Link to="/" onClick={() => toggleMobileMenu()}>Home</Link></li>
            {navItems.map((navItem, i) => {
                let shortUrl = navItem.url.substring(navItem.url.indexOf("/") + 1);
                if (navItem.url.match(/^\s?http(s?)/gi)) {
                    return <li key={i}><a className={navClass} href={navItem.url} target="_blank" rel="noopener noreferrer" onClick={() => toggleMobileMenu()}>{navItem.label}</a></li>
                } else {
                    return <li  key={i}><Link className={navClass} to={`/${shortUrl}`} onClick={() => toggleMobileMenu()}>{navItem.label}</Link></li>
                }
            })}
            {identity.user ?
            <>
              <li  key={'mobile-nav-profile'} style={{paddingTop: '2.5rem'}}><Link to="/account/profile">{identity.user.email}</Link></li>
              <li  key={'mobile-nav-logout'}><LoginButton link linkClick={identity.logout} linkKeyDown={identity.logout}>Logout</LoginButton></li>
            </> :
            <>
              <li  key={'mobile-nav-subscribe'} style={{paddingTop: '2.5rem'}}><Link to={`/${data.ghostPage.slug}/`}>Join Us</Link></li>
              <li  key={'mobile-nav-login'}><LoginButton link user={identity.user} showPopup={showLoginPopup} setShowPopup={setShowLoginPopup}>Login</LoginButton></li>
            </>
            }
        </ul>
      </div>
    </nav>
  )
}

MobileMenu.propTypes = {
    navItems: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            url: PropTypes.string.isRequired,
        }).isRequired,
    ).isRequired,
  }


export default MobileMenu
