// src/components/privateRoute.js
import React from "react";
import { useIdentityContext } from 'react-netlify-identity-gotrue';

const PrivateRoute = ({ component: Component, path, postId, site, ...rest }) => {
  const identity = useIdentityContext();

  return identity.user ? <Component postId={postId} site={site} {...rest} /> : null;
}

export default PrivateRoute
