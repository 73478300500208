import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';
import { useIdentityContext } from 'react-netlify-identity-gotrue';

import Header from './header';
import MobileMenu from './mobileMenu';
import Footer from './footer';
import RegisterForm from './registerForm';
import Popup from './popup';

const Layout = ({ location, site, children, bodyClass, post}) => {
  const identity = useIdentityContext();

  const [mobileMenuActive, setMobileMenuActive] = useState(false);
  const [showRegisterPopup, setShowRegisterPopup] = useState(false);
  const [showLoginPopup, setShowLoginPopup] = useState(false);
  const [engagementUpdated, setEngagementUpdated] = useState(false);
  const [gtmDataSent, setGtmDataSent] = useState(false);

  let mobileMenuClass = "site-nav-mobile";
  if (mobileMenuActive) { mobileMenuClass += " active" };
  const twitterUrl = site.twitter ? `https://twitter.com/${site.twitter.replace(/^@/, ``)}` : null;
  const facebookUrl = site.facebook ? `https://www.facebook.com/${site.facebook.replace(/^\//, ``)}` : null;

  const handleMobileMenuKeyDown = ev => {
    if (ev.keyCode === 13) {
      // enter to open and close
      toggleMobileMenu();
    } else if (ev.keyCode === 27 && mobileMenuActive) {
      // escape to close
      toggleMobileMenu();
    }
  }

  const toggleMobileMenu = () => {
    setMobileMenuActive(!mobileMenuActive);
  }

  //update engagement with ghost id at beginning of a new session
  useEffect(() => {
    let updated = false;
    let subd = false;
    if (typeof window !== `undefined`) {
      updated = sessionStorage.getItem('epi.updated');
    }
    if (!updated && identity.user?.app_metadata?.ghost_id) {
      updateEngagement(identity.user.app_metadata.ghost_id);
      if (typeof window !== `undefined`) {
        sessionStorage.setItem('epi.updated', true);
      }    
    }

  }, [identity.user])

  //if not post, provide blank values for tag and author
  useEffect(() => {
    if (!gtmDataSent && !post && typeof window !== 'undefined'){
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({ postTag: '', postAuthor: '' });
      setGtmDataSent(true);
    }
  }, [gtmDataSent]);

  const updateEngagement = async ( ghostId ) => {
    async function postData(url) {
       const response = await fetch(url, {
         method: 'POST',
         mode: 'cors',
         headers: {
           'Content-Type': 'application/json',
         },
         body: JSON.stringify({ghost_id: ghostId})
       });
       return response.json();
     }
    return await postData('/api/updateGhostEngagement/');
  }


  return (
    <>
      <Helmet>
          <html lang={site.lang} />
          <style type="text/css">{`${site.codeinjection_styles}`}</style>
          <link href="//maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css" rel="stylesheet" />
          <body className={bodyClass} />
      </Helmet>
      <div className="viewport">
          <div className="viewport-top">
              {/* The main header section on top of the screen*/}
              <div className='site-header'>
                <Header
                  site={site}
                  mobileMenuClass={mobileMenuClass}
                  toggleMobileMenu={toggleMobileMenu}
                  handleMobileMenuKeyDown={handleMobileMenuKeyDown}
                  twitterUrl={twitterUrl}
                  facebookUrl={facebookUrl}
                  showLoginPopup={showLoginPopup}
                  setShowLoginPopup={setShowLoginPopup}
                  showRegisterPopup={showRegisterPopup}
                  setShowRegisterPopup={setShowRegisterPopup}
                 />
              </div>

              <MobileMenu
                active={mobileMenuActive}
                toggleMobileMenu={toggleMobileMenu}
                navItems={site.navigation}
                showLoginPopup={showLoginPopup}
                setShowLoginPopup={setShowLoginPopup}
                showRegisterPopup={showRegisterPopup}
                setShowRegisterPopup={setShowRegisterPopup}
               />
              {/* The mobile menu hamburger button */}
              <div role="button" tabIndex={0} className={mobileMenuClass} onClick={() => toggleMobileMenu() } onKeyDown={ (e) => handleMobileMenuKeyDown(e) }>
                <span></span>
              </div>

              <Popup showPopup={showLoginPopup} setShowPopup={setShowLoginPopup} >
                <h2 style={{textAlign: 'center', paddingBottom: '2rem'}}>Sign in to access your account</h2>
                <RegisterForm loginType='login' setShowPopup={setShowLoginPopup} />
              </Popup>

              <main className="site-main" id="site-main">
                  {/* All the main content gets inserted here, index.js, post.js */}
                  {children}
              </main>

          </div>

          <div className="viewport-bottom">
              {/* The footer at the very bottom of the screen */}
              <Footer site={site} twitterUrl={twitterUrl} facebookUrl={facebookUrl} />

          </div>
      </div>
    </>
  )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
    bodyClass: PropTypes.string,
    isHome: PropTypes.bool,
    data: PropTypes.shape({
        allGhostSettings: PropTypes.object.isRequired,
    }).isRequired,
}

const LayoutSettingsQuery = props => (
    <StaticQuery
        query={graphql`
            query GhostSettings {
                allGhostSettings {
                    edges {
                        node {
                            ...GhostSettingsFields
                        }
                    }
                }
            }
        `}
        render={data => <Layout data={data} {...props} />}
    />
)

export default LayoutSettingsQuery
