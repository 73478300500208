// src/pages/account.js
import React from "react"
import { graphql } from "gatsby"
import { Router } from "@reach/router"
import Layout from "../components/layout"
import Profile from "../components/profile"
import PrivateRoute from "../components/privateRoute"

const Account = ({ data, location }) => {
  const site = data.allGhostSettings.edges[0].node;

  return (
    <Layout location={location} site={site}>
      <Router>
        <PrivateRoute path="/account/profile" component={Profile} />
      </Router>
    </Layout>
  )
}

export default Account

export const pageQuery = graphql`
  query {
    allGhostSettings {
        edges {
            node {
                ...GhostSettingsFields
            }
        }
    }
  }
`
