import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIdentityContext } from 'react-netlify-identity-gotrue';

import SubscribeForm from './subscribeForm';

import "./styles/hero.css";


const Hero = ({ children, site }) => {
  const identity = useIdentityContext();

  let subd = false;
  if (typeof window !== `undefined`) {
    subd = localStorage.getItem('epi.subd');
  }

  return (
    <div
       className="site-header-background responsive-header-img"
       style={{backgroundImage: `url(${site.cover_image})`}}
       >
       <div className="site-hero">
         <div className="site-hero-overlay">
           <div className="site-hero-content">
               <h1 className="site-description">
                   {site.description}
               </h1>
               {!subd && !identity.user &&
                 <SubscribeForm /> }
           </div>
         </div>
       </div>
     </div>
  )

}


Hero.propTypes = {
    children: PropTypes.node,
    site: PropTypes.object
}



export default Hero
