// src/components/profile.js
import React, { useState } from 'react';
import { useIdentityContext } from 'react-netlify-identity-gotrue';
import LoadingOverlay from "../components/loadingOverlay";
import { useStaticQuery, graphql } from 'gatsby';

import Button from './button';
import Popup from './popup';
import RegisterForm from './registerForm';


const Profile = ({ location }) => {
  const identity = useIdentityContext();
  const [loadingBilling, setLoadingBilling] = useState(false);
  const [showUpdatePopup, setShowUpdatePopup] = useState(false);

  const data = useStaticQuery(graphql`
    query ProfileQueryCore {
      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `)

  const manageStripeBilling = async ( customerID ) => {
    setLoadingBilling(true);
    async function postData(url) {
       const response = await fetch(url, {
         method: 'POST',
         mode: 'cors',
         headers: {
           'Content-Type': 'application/json',
         },
         body: JSON.stringify({stripe_customer: customerID, return_url: `${data.site.siteMetadata.siteUrl}/account/profile/`  })
       });
       return response.json()
     }
    return await postData('/api/stripeBillingLink').then(
      (link) => {
        window.location.href = link;
      }
    );
  }

  if (!identity.user) {
    return <div>Loading...</div>;
  }
  return(
    <>
      <section style={{textAlign: 'center', paddingTop: '3em'}} className="post-full-content">
        <h1 className="post-full-title">My Account</h1>
        <p>Email: {identity.user.email}</p>
        <div style={{paddingTop: '1em'}}>
          <h2 className="post-full-title">Manage Membership</h2>
          <Button textSize={'lg'} onClick={() => manageStripeBilling(identity.user.app_metadata.stripe_customer)}>Manage Membership</Button>
        </div>
        <div style={{paddingTop: '1em', paddingBottom: '2em'}}>
          <h2>Change Password</h2>
          <Button
            onClick={ (e) => setShowUpdatePopup(!showUpdatePopup) }
            textSize={'lg'}
             >Change Password</Button>
        </div>
      </section>
      <LoadingOverlay showOverlay={loadingBilling} />
      <Popup showPopup={showUpdatePopup} setShowPopup={setShowUpdatePopup} >
        <h2 style={{textAlign: 'center', paddingBottom: '2rem'}}>Change your Password</h2>
        <RegisterForm loginType='update' setShowPopup={setShowUpdatePopup} />
      </Popup>
    </>
  )
}

export default Profile;
